html, body, #root {
  height: 100%
}

.has-tooltip {
  border-bottom: 1px dotted #0091ea;
}

/* Dialogs */
.title-content {
  position: absolute !important;
  white-space: nowrap;
}
.dialog-section {
  padding: 24px;
}
.dialog-section.next {
  padding-top: 5px;
}
.dialog-section h4 {
  margin: 0 0 15px 0
}
.dialog-section h4.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.dialog-section.cascade {
  margin-left: 24px;
}

/* Login */
form.login {
  width: 350px;
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  background-color: #fff;
}
form.login .MuiFormControl-root {
  width: 100%;
  margin-bottom: .25rem;
}
form.login button {
  margin-top: 1rem;
}

/* Header selects */
.header-select {
  position: absolute;
  z-index: 1000;
}
.header-select .MuiInput-underline:before {
  display: none;
}
.header-select .MuiInput-underline:after {
  display: none;
}
.header-select .MuiSelect-select:focus {
  background-color: #fff;
}
.MuiListItem-root.Mui-selected.header {
  background-color: #fff;
}
.header-select .MuiChip-root {
  cursor: pointer;
}


/* Resources */
.resources-container td.MuiTableCell-root.MuiTableCell-body, .resources-container th[class*="TableHeaderCell-cell"] {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.resources-plant-selector {
  top: 5.1rem;
  left: 20rem;
}
.resource-name {
  top: 1.7rem;
  left: 18rem;
  width: 17rem;
  color: #777;
}
.plant-name {
  left: 8.15rem;
  top: 3.9rem;
}
.sievetests-history {
  min-height: 15rem;
  max-height: 25rem;
  overflow-y: auto;
}
.sievetests-history .restweight {
  display: inline-block;
}
.sievetests-history .active {
  display: block;
  float: right;
  line-height: 1.85rem;
}
.resource-info {
  position: absolute;
  left: 22.45rem;
  top: 1.55rem;
}

.slider-perc-input {
  margin: 0;
  width: 60px;
}
.slider-perc-input input {
  padding: 5px;
}
.slider-perc-label {
  width: 100%;
}

/* Recipes */
.recipesSwitch, .activeSwitch {
  z-index: 1000;
  position: absolute;
  top: 78px;
  left: 170px;
}
.recipesSwitch.active {
  left: 375px;
}
.recipes-plant-selector {
  top: 5.1rem;
  left: 35.5rem;
}
.plants-edit {
  text-align: right;
  margin-bottom: .3rem !important;
}

.revisions-list {
  padding-left: 0;
  max-height: 537px;
  overflow-y: auto;
}
.revisions-list .MuiTypography-body2 {
  font-size: 80%;
}

/* Recipe page: recipe name positioning */
#recipeName1 {
  background-color: #fff;
}
.recipeName {
  position: absolute;
  top: 5.5rem;
  left: 13rem;
  font-weight: 500;
}
div.recipename {
  padding-left: 24px;
  padding-right: 24px;
}
button.additionInput {
  min-width: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.recipe-name {
  position: absolute;
  top: 9rem;
  left: 1.5rem;
  background-color: rgb(232, 234, 246);
  z-index: 1000;
  padding: 0.5rem;
}

.plantSelector .MuiFilledInput-root {
  background-color: #3f51b5;
  color: white;
}
.plantSelector .MuiFilledInput-root:hover, .plantSelector .MuiFilledInput-root.Mui-focused {
  background-color: #303f9f;
  color: white;
}
.plantSelector .MuiFormLabel-root, .plantSelector .MuiFormLabel-root.Mui-focused  {
  color: #999;
}
.plantSelector .MuiFilledInput-root .MuiSelect-icon {
  color: white;
}
.plantSelector .MuiFilledInput-root.Mui-disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.resource .plants {
  position: fixed;
  width: 15rem;
  margin-top: 2rem;
  max-height: 18rem;
  overflow-y: auto;
}
.resource .plants .MuiButtonGroup-vertical {
  width: 100%;
}
.resource .plants .active {
  background-color: #303f9f;
  color: white;
}

/* MUI Material */

.MuiPaper-elevation4 {
  box-shadow: none;
}
.MuiOutlinedInput-input, .MuiOutlinedInput-root, .MuiOutlinedInput-multiline, .WAMuiChipInput-chipContainer-33,
th[class*="TableFilterCell-cell"], th[class*="TableStubCell-cell"] {
  background-color: #f7f8fc;
}
th[class*="TableFilterCell-cell"] input {
  padding: 8px;
}
th[class*="TableFilterCell-cell"] .MuiTextField-root {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
th[class*="TableFilterCell-cell"] .MuiSelect-select {
  padding-top: 8px;
  padding-bottom: 8px;
}
th[class*="TableHeaderCell-cell"] {
  padding-top: 0;
  padding-bottom: 0;
}
td.MuiTableCell-root.MuiTableCell-body {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.MuiFormControl-marginDense {
  margin-top: 5px !important;
}
.MuiOutlinedInput-inputMarginDense {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.MuiPaper-rounded {
  border-radius: 0 !important;
}
.MuiTableCell-head {
  line-height: inherit !important;
}
.MuiButton-root {
  text-transform: none !important;
}
.MuiStepContent-root {
  margin: 0;
}
.MuiStepConnector-vertical {
  padding: 0;
}
.MuiStepConnector-lineVertical {
  min-height: 15px;
}
.MuiInputBase-root, .MuiFormLabel-root {
  font-size: .925rem !important;
}
.MuiInputAdornment-root {
  color: #999
}
